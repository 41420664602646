import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ComponentPreview,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import legendPreviewData from '../../../../data/previews/legend.yml';
import pageHeroData from '../../../../data/pages/components.yml';
import styles from './styles/legend.module.scss';

const IconEffectArrow = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconEffectArrow;
    return <Component {...props} />;
  },
});

const IconEffectFreehand = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconEffectFreehand;
    return <Component {...props} />;
  },
});

const Legend = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Legend;
    return <Component {...props} />;
  },
});

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      legendElement: 'elementidentifier',
    };
  }

  onSwitch = value =>
    this.setState({
      legendElement: value,
    });

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Legend"
        designCode
        subnav="components">
        <PageHero
          heroData={pageHeroData}
          tierTwo="Data Visualization"
          tierThree="Legend"
        />
        <V5Notice
          code
          componentName="Legend"
          storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-data-visualization--docs#legend"
        />
        <PageNavigation
          links={['Elements', 'Usage', 'Accessibility', 'Platform']}
        />
        <Section title="Elements">
          <Paragraph>
            A legend is comprised of an identifier, a label and sometimes a
            category. It's content is always organized to explain the data on
            the diagram it accompanies.
          </Paragraph>
          <ComponentPreview
            name="linkState"
            layout="split"
            previewData={legendPreviewData.elements}
            onChange={this.onSwitch}>
            <div
              className={
                styles.legendElementsWrapper + ' ' + this.state.legendElement
              }>
              <Legend
                groups={[
                  {
                    categoryName: 'Result',
                    items: [
                      {
                        symbol: (
                          <div
                            style={{
                              backgroundColor: 'INDIANRED',
                              height: 12,
                              width: 12,
                              margin: 2,
                              borderRadius: 50,
                            }}
                          />
                        ),
                        label: 'Pass',
                      },
                      {
                        symbol: (
                          <div
                            style={{
                              backgroundColor: 'DARKCYAN',
                              height: 12,
                              width: 12,
                              margin: 2,
                              borderRadius: 50,
                            }}
                          />
                        ),
                        label: 'Error',
                      },
                      {
                        symbol: (
                          <div
                            style={{
                              backgroundColor: 'BURLYWOOD',
                              height: 12,
                              width: 12,
                              margin: 2,
                              borderRadius: 50,
                            }}
                          />
                        ),
                        label: 'Goal',
                      },
                    ],
                  },
                  {
                    categoryName: 'Runs',
                    items: [
                      {
                        symbol: <IconEffectArrow size="small" />,
                        label: 'Straight',
                      },
                      {
                        symbol: <IconEffectFreehand size="small" />,
                        label: 'Loopy',
                      },
                    ],
                  },
                ]}
              />
            </div>
          </ComponentPreview>
          <Paragraph>
            <strong>The identifier</strong> should only ever be a{' '}
            <Link href="/guidelines/colors" isDesignCodeLink>
              color
            </Link>
            , a pattern or a symbol, like a{' '}
            <Link href="/components/icons" isDesignCodeLink>
              small icon
            </Link>
            . It shouldn’t repeat in the legend, since that will likely be
            confusing as to what statistical data it’s representing.{' '}
          </Paragraph>
          <Paragraph>
            <strong>The identifier label</strong> must distinctly describe the
            specific information it relates to. Be as concise as possible
            without causing confusion about its meaning.
          </Paragraph>
          <Paragraph>
            <strong>Category labels</strong> are needed in some cases to keep
            like-items corralled when providing a lot of information. Category
            labels should <em>only</em> be used when there are three or more
            items that fit into two groups.
          </Paragraph>
        </Section>
        <Section title="Usage">
          <SectionSubhead>Size</SectionSubhead>
          <Paragraph>
            To keep the information organized in an easy-to-read way, we
            recommend only ever using a <em>small</em> identifier. The bounding
            area will follow our size guideline, but the maximum size of the
            object should be 12 x 12 pixels, so as not to overpower the text.
          </Paragraph>
          <DontDo
            dontText="use medium or large sizes for identifiers."
            doText="use small identifiers to keep it and label vertically aligned."
            imgFilename="legend-size"
          />
          <SectionSubhead>Placement</SectionSubhead>
          <Paragraph>
            Legends must <em>always</em> accompany a diagram. They can’t float
            out on their own, and they shouldn’t list information for data that
            isn’t represented on the chart. Legends should always be placed
            above the diagram it’s describing.
          </Paragraph>
          <DontDo
            dontText="provide a statistical diagram without a legend."
            doText="include all information represented."
            imgFilename="legend-placement"
          />
          <DontDo
            dontText="list identifiers without labels."
            doText="write labels clearly and succinctly."
            imgFilename="legend-identifier-label"
          />
          <DontDo
            dontText="provide category labels for individual identifiers."
            doText="break up identifiers in groups that make sense."
            imgFilename="legend-category-label"
          />
        </Section>
        <AccessibilityAuditComponentResultsSection componentName="Data Visualization / Legend" />
        <Section title="Platform">
          <PlatformTable />
        </Section>
      </PageWithSubNav>
    );
  }
}
